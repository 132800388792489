import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--promo);
    //max-height: 540px;
    height: 540px;
    width: 1356px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    div.text {
      h1{
        margin-left: 100px;
        text-align: left;
        font-size: 60px;
        margin-top: 50px;
        font-weight: 600;
        color: var(--promo-sec);
        
    }
      h2 {
        max-width: 675px;
        max-height: 320px;
        margin-top: 15px;
        margin-right: 135px;
        margin-left: 100px;
        text-align: right;
        font-size: 45px;
        font-weight: 600;
        color: var(--font-color-sec);
    }

    div {
      img.bar { 
        margin-left: 140px;
        margin-right: 140px;
      }
      
    }
  }
`;

export const Content = styled.div`
background-color: var(--promo);
  img {
  margin-left: 120px;
  max-width: 365px;
  max-height: 365px;
  }
`;

export const Sc = styled.div`
  background-color: var(--promo);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  max-width: 840px;
  max-height: 270px;
`;


export const S = styled.div`
background-color: var(--promo);
margin-left: 40px;
margin-top: 280px;
 img.spotfy {
  width: 55px;
  height: 55px;
  }
  img.youtube{
    width: 65px;
    height: 47.67px;
  }
strong {
  color: var(--font-color-sec); 
    justify-content: center;
    text-align: center;
  }

`;


   

