import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
//import { Link } from 'react-router-dom';
import DaocaLogo from '../../../assets/PLogos/DaocaSession.png'

export function Daoca() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={DaocaLogo} alt="logo"></img>
          <div>
                <h1>Daoca Sessions</h1>
                <h6>Arte e Cultura</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            Nascido como uma iniciativa de fomento de cultura e lazer aliados ao Hip Hop, o projeto atua<br/>
            no bairro Cidade de Deus como uma forma de driblar a violência e carência de<br/>
            promoções de arte na localidade.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Leste<br/>
            Cidade de Deus
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Todos os públicos
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Leonardo Zuani "Doni"
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 99448-9924<br/>
            astraxxxtv@gmail.com 
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a 
            href='https://www.instagram.com/daocasessions'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
