import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import logoNamaloca from '../../assets/namalocaLogo.png'
import { Container, Content } from './styles'

const schema = Yup.object({
  email: Yup.string().email("Formato de e-mail inválido.")
}).required();

export function Home() {

  const { register, handleSubmit, formState:{errors}} = useForm({
    resolver: yupResolver(schema)
  });
  
  function onSubmit(email:any) {
    console.log(email);
  }

  return (
    <Container className='home'>
        <div className='body'>
          <img src={logoNamaloca} alt="Logo namaloca"/>
          <h1>Uma plataforma de conexão e engajamento <br/> 
              na busca por justiça social, floresta em pé <br/>
              e cultura pulsante no Amazonas </h1>
            <Content onSubmit={handleSubmit(onSubmit)}>
              <input type="text"
              {...register("email", {required: true})}
              placeholder='Seu melhor e-mail'
              />
              <button className="button" type="submit">Conectar para engajar</button>
              <br/>
              <span>{errors.email?.message}</span>
            </ Content>
        </div>  
    </Container>
  )
}