import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
import AkimLogo from '../../../assets/PLogos/AKIM.jpeg';
import fbImg from '../../../assets/facebookicon.png';
import { FooterPlat } from '../../FooterPlat';
//import { Link } from 'react-router-dom';

export function Akim() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={AkimLogo} alt="logo"></img>
          <div>
                <h1>Associação dos índios Kokama<br/>residentes no município de Manaus (AKIM)</h1>
                <h6>Identidade étnica, Direito indígena, Renda e economia</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            Atua há mais de 16 anos na educação escolar indígena para adultos, crianças e adolescentes<br/>
            no aprendizado da língua materna Kokama. Atualmente conta com projetos<br/>
            de música e dança indígena na comunidade e possui 186 famílias indígenas associadas.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Leste<br/>
            (Estrada do Brasileirinho) - Zona Rural
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Atua com crianças de 3 anos em diante, adolescentes e adultos de baixa renda e escolaridade diversa.
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Jardeline Dos Santos
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 99211-6107<br/>
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a 
            href='https://www.facebook.com/akimindios.kokama'>
            <img className='insta'src={fbImg} 
              alt='logo facebook'/>
          </a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
