import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
import fbImg from '../../../assets/facebookicon.png';
//import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
//import { Link } from 'react-router-dom';

export function Jofo() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={fbImg} alt="logo"></img>
          <div>
                <h1>Casa de conhecimento Murui Bue Witoto</h1>
                <h6>Identidade étnica, Educação, Arte e cultura</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            Grupo de educação e fortalecimento da identidade étnica, cultural e linguística das crianças<br/>
            indígenas. A casa do conhecimento possui hoje 30 crianças, com faixa etária de<br/>
            4 a 12 anos, algumas crianças cursando o ensino fundamental e outras crianças com<br/>
            dificuldade de estudar nas escolas. O projeto atende os filhos de indígenas de renda<br/>
            baixa, sendo em sua maioria mães solteiras. Através do ateliê fomenta a geração<br/>
            de renda das mulheres indígenas, com a confecção e venda de roupas com<br/>
            grafismos indígenas e artesanato. O ateliê conta hoje com 6 costureiras, faixa etária de 32 a 55<br/>
            anos, baixa renda, entre elas mães solteiras e casadas, maioria com ensino médio completo.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Rural<br/>
            Tarumã
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Indígenas
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Vanda Ortega Witoto
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 99618-2026<br/>
            vanda.ortegaam@gmail.com <br/>
            derequine2020@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
