import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1356px;
  height: 680px;
  margin: 0;
  background-color: var(--button);
  
`;

export const Footer = styled.footer`
width: 1356px;
height: 580px;
background-color: var(--button);
`;

export const FooterDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 150px;
margin-left: 340px;
justify-content: center;
background-color: #fff;
width: 630px;
height: 270px;
border-radius: 10px;

button {
    font-size: 20px;
    border-radius: 40px;
    margin-top: 30px;
    height: 70px;
    width: 260px;
    background-color: var(--button);
    border: 0;
    color: var(--font-color-sec);

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const Text = styled.h1`
font-size: 22px;
`;
