import styled from "styled-components";

export const Container = styled.div`
    height: 100%;
    width: 1356px;
    margin: 0;
    display: flex;
    align-items: center;
`;

export const Content = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 120px;
  margin-left: 290px;
  align-items: left;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 83px;
  
  img {
    border-radius: 50%;
    max-width: 400px;
    max-height: 400px;
  }
  div {
    margin-left: 135px;
    h1{
      font-size: 45px;
      font-weight: 700;
      color: var(--button);
      margin-bottom: 35px;
    }
    h6 {
      font-size: 22px;
      font-weight: 200;
      color: var(--button);
    }
  }

`;

export const Description = styled.div`
  margin-bottom: 140px;
  strong {
    font-size: 22px;
    color: var(--button);
    margin-bottom: 25px;
  }
  h6 {
      font-size: 22px;
      font-weight: 200;
    }

`;

export const Area = styled.div`
  margin-bottom: 140px;
  strong {
    font-size: 22px;
    color: var(--button);
    margin-bottom: 15px;
  }
  h6 {
      font-size: 22px;
      font-weight: 200;
    }

`;

export const Public = styled.div`
 margin-bottom: 140px;
 strong {
    font-size: 22px;
    color: var(--button);
    margin-bottom: 15px;
  }
  h6 {
      font-size: 22px;
      font-weight: 200;
    }

`;

export const Rep = styled.div`
  margin-bottom: 140px;
  strong {
    font-size: 22px;
    color: var(--button);
    margin-bottom: 15px;
  }
  h6 {
      font-size: 22px;
      font-weight: 200;
  }
`;

export const ContactPage = styled.div`
  margin-bottom: 140px;
  strong {
    font-size: 22px;
    color: var(--button);
    margin-bottom: 15px;
  }
  h6 {
      font-size: 22px;
      font-weight: 200;
  }
`;

export const Social = styled.div`
  margin-bottom: 140px;
  display: flex;
  flex-direction: column;
  strong {
    font-size: 22px;
    color: var(--button);
    margin-bottom: 15px;
  }
  img.fb {
  width: 55px;
  height: 55px;
  }
`;

