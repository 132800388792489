import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
//import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import MandalaLogo from '../../../assets/PLogos/ColetivoMandala.png'
//import { Link } from 'react-router-dom';

export function Mandala() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={MandalaLogo} alt="logo"></img>
          <div>
                <h1>Coletivo Mandala</h1>
                <h6>Arte e cultura</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            O projeto ações e programações voltadas para as periferias de Manaus no intuito de levar<br/>
            artes através dos artistas do Hip Hop e suas vertentes. Temos a missão de ajudar<br/>
            crianças adolescentes nas margens da criminalidade transformando suas vidas e a comunidade.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Áreas periféricas de Manaus<br/>
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Moradores de áreas de vulnerabilidade
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Alan Noronha Ferreira
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 98805-1101<br/>
            alanferreira4396@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>

        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
