import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
//import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import MuraLogo from '../../../assets/PLogos/AIRaimundoMura.jpeg'

//import { Link } from 'react-router-dom';

export function Mura() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={MuraLogo} alt="logo"></img>
          <div>
                <h1>Associação Raimundo Mura</h1>
                <h6>Identidade étnica, Direito indígenas, Renda e economia</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            Associação que há mais de 20 anos luta pelos direitos de moradia dos indígenas urbanos,<br/>
            sendo responsável por muitos dos loteamentos e casas adquiridas para os indígenas.<br/>
            Além do trabalho político de reivindicar o direito básico da moradia dos<br/>
            indígenas, eles estão iniciando um projeto de fabricação de sabão para implementar<br/>
            a renda dos indígenas. Com 135 associados, a maior parte mulheres, solteiras e de baixa renda.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Norte<br/>
            Conj. Cidadão doze
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Indígenas associados
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Cacica Zilda Mura
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 99311-7344<br/>
            zildamoura344@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>

        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
