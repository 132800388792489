import styled from "styled-components";

export const Container = styled.div`
background-color: var(--button);
position: relative;
border-radius: 15px;
  button {
    font-size: 30px;
    font-weight: bold;
    margin: 5px;
    background-color:var(--button);
    color: var(--font-color-sec);
    border: 0;
    align-self: right;
    align-items: right;
  } 
  h3{
  font-size: 50px;
  font-weight: bold;
  margin: 20px;
  color: var(--font-color-sec);
 } 
`;

export const Content = styled.div`
text-align: center;
align-content: space-between;

`
