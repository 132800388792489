import styled from "styled-components";
import { Link } from 'react-router-dom';

export const Container = styled.header`
  width: 100%;
  width: 1356px;
  height: 120px;
  background-color: var(--font-color-sec);
  border-style: none none groove none;
  border-color: lightgray;
  img {
    margin-right: 10px;
    max-height: 117px;
    max-width: 400px;
  }
 
`;

export const Content = styled.nav`
  max-width: 1620px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
      text-decoration: none;
      color: var(--font-color-ter);
      }
  `;

  export const Ul = styled.ul` 
    display: flex;
    flex-direction: row;
    font-size: 20px;
    list-style: none;
    `;

    export const Li = styled(Link) ` 
      text-decoration: none;
      color: var(--font-color-ter);
     
      margin: 10px;
    
    `;
