import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import { Link } from 'react-router-dom';

export function PontaDeLanca() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={fbImg} alt="logo"></img>
          <div>
                <h1>Coletivo Ponta de Lança</h1>
                <h6>Meio ambiente</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            O Coletivo Ponta de Lança foi fundado por jovens mulheres negras moradoras de bairros<br/>
            periféricos da cidade de Manaus que adentraram à universidade pública e que, diante das<br/> 
            relações de alteridades nesse espaço institucional, sentiram a urgência de se auto<br/> 
            organizarem para construir efetivos processos de (re)conexão com as suas periferias de<br/>
            origem. Desde então, se auto organizam de forma autônoma em coletivo, utilizando a<br/> 
            comunicação popular e a produção audiovisual documental para a promoção de espaços de<br/>
            escuta ativa das populações periféricas amazônicas.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Áreas periféricas<br/>
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Mulheres jovens, negras e periféricas
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Raquel Karina Cardoso de Souza
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 98499-4503<br/>
            canal.pontadelanca@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <Link 
            to='https://www.instagram.com/coletivopontadelanca'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </Link>
          <Link 
            to='https://www.facebook.com/coletivopontadelanca'>
            <img className='insta'src={fbImg} 
              alt='logo facebook'/>
          </Link>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
