import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  :root {

    --backgrond: #f2f2f2;
    --font-color: #333333;
    --font-color-sec: #ffffff;
    --font-color-ter: #707070;
    --button: #5157cf;
    --promo: #e12f38;
    --promo-sec: #ffd74f;
    --promo-ter: #f3da83;
    --color-sec: #0b9353;
    
   // @media (max-width: 1700px) {
   //   font-size: 94%;
   // }

   // @media (max-width: 1080px) {
   //   font-size: 93.75%;
    //}

   //@media (max-width: 720px) {
   // width: 75%;
   // height: 75%;
   // font-size: 80.5%;
   // }
   
  }
  //#root {
   // height: 100%;
   // width: 100%;
 // }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
 
  body {
    background: var(--backgrond);
    -webkit-font-smoothing: antialiased;

  }

  border-style, input, textarea, button {
    font-family: 'Lato', sans-serif;;
    font-weight: 400;
  }
  
  h1, h2, h3, h3, h5, h6, strong, a, link {
    font-family: 'Lato', sans-serif;;
  }

  button {
    cursor: pointer;
    //background: var(--button);
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

`