import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import TarumaLogo from '../../../assets/PLogos/TarumaAlive.jpg'
//import { Link } from 'react-router-dom';

export function TarumaAlive() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={TarumaLogo} alt="logo"></img>
          <div>
                <h1>Tarumã Alive</h1>
                <h6>Meio ambiente</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            Tarumã Alive é um movimento em prol das águas de Manaus, tendo como foco a proteção da<br/>
            Bacias Hidrográficas do Tarumã, importante rio, ainda vivo na cidade. O Tarumã Alive,<br/>
            promove através de campanhas, eventos e ações ambientais e educacionais,<br/>
            a importância de preservação dos rios. E já se tornou símbolo da nova consciência<br/>
            cultural que se dissemina entre os públicos jovem e adultos da população manauara.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Rural<br/>
            Tarumã
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Atua com o público jovem e adulto de faixa etária de 18 anos a 45 anos,<br/>
            em sua maioria ensino médio completo, estado civil variado, condição econômica de renda baixa e média.
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Marcia Novo
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 98122-2001<br/>
            festivaltarumaalive@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a 
            href='https://www.instagram.com/tarumaalive'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </a>
          <a 
            href='https://www.facebook.com/tarumaalive'>
            <img className='insta'src={fbImg} 
              alt='logo instagram'/>
          </a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
