import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import PedrocaLogo from '../../../assets/PLogos/PedrocaArtesanato.png'
//import { Link } from 'react-router-dom';

export function PedrocaArt() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={PedrocaLogo} alt="logo"></img>
          <div>
                <h1>Pedroca Artesanatos</h1>
                <h6>Renda e economia</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            Nascidos na Pastoral da Juventude, o grupo trabalha com confecção de artesanatos e velas e<br/>
            participa de feiras, exposições, seminários e outras atividades coletivas. Além disso,<br/>
            realizam também oficinas de artesanatos sob as dimensões da economia solidária,<br/>
            em espaços como igrejas, escolas, universidades e Organizações da Sociedade Civil (OSCs).
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Mutirão/Novo Aleixo/Jorge Teixeira
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Todos os públicos
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Mariany Silva de Moura
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 99296-1305<br/>
            pedrocaartesanatos@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a 
            href='https://www.instagram.com/pedrocasartesanatos'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
