import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import LogoMaisSaude from '../../../assets/PLogos/MaisSaude.png'
//import { Link } from 'react-router-dom';


export function MaisSaudeVc() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={LogoMaisSaude} alt="logo"></img>
          <div>
                <h1>Mais Saúde Para você</h1>
                <h6>Saúde e bem estar</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            Nascido dentro das Unidades Básicas de Saúde, o projeto visa o bem estar<br/>
            e melhoria na qualidade de vida especialmente de diabéticos, hipertensos,<br/>
            obesos e pessoas com depressão. Vários profissionais da área de saúde<br/>
            atuam com atividades adequadas para este público.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Leste<br/>
            Parque das Garças
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Pessoas em situação de vulnerabilidade e pacientes de doenças crônicas
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Silvia Brito
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 99508-8568<br/>
            silviafbsantiago24@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a 
            href='https://www.instagram.com/maisssaude9'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
