import React from "react"; 
import { GlobalStyle } from "./styles/global";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Header } from "./Components/Header";
import { Home } from "./Components/Home";
import { Know } from "./Components/Know";
import { Diary } from "./Components/Diary"
import { Promo } from "./Components/Promo";
import { Publi } from "./Components/Publi";
import { Contact } from "./Components/Contact";
import { About } from "./Components/About";

import { FaceB } from "./Components/Redirect/FaceB";
import { Medium } from "./Components/Redirect/Medium";
import { Insta } from "./Components/Redirect/Insta";
import { Spotify } from "./Components/Redirect/Spotify";
import { YouTube } from "./Components/Redirect/YouTube";
import { Form } from "./Components/Redirect/Form";

import { Platform } from "./Components/Platform"
import { Akim } from "./Components/Platform/Akim";
import { Amism } from "./Components/Platform/Amism";
import { AssocFanm } from "./Components/Platform/AssocFanm";
import { Assotram } from "./Components/Platform/Assotram";
import { CasaMatagal } from "./Components/Platform/CasaMatagal";
import { Daoca } from "./Components/Platform/Daoca";
import { EnsinoParaTodos } from "./Components/Platform/EnsinoParaTodos";
import { Escangalho } from "./Components/Platform/Escangalho";
import { EscolinhaSk8 } from "./Components/Platform/EscolinhaSk8";
import { HackerUrbano } from "./Components/Platform/HackerUrbano";
import { HipHop } from "./Components/Platform/HipHop";
import { InhaaBe } from "./Components/Platform/InhaaBe";
import { Jofo } from "./Components/Platform/Jofo";
import { Kokama } from "./Components/Platform/Kokama";
import { MaisSaudeVc } from "./Components/Platform/MaisSaudeVc";
import { MalocaSocial } from "./Components/Platform/MalocaSocial";
import { Mandala } from "./Components/Platform/Mandala";
import { Manifesta } from "./Components/Platform/Manifesta";
import { Marupiara } from "./Components/Platform/Marupiara";
import { Mura } from "./Components/Platform/Mura";
import { PedrocaArt } from "./Components/Platform/PedrocaArt";
import { Pentecostal } from "./Components/Platform/Pentecostal";
import { Plurais } from "./Components/Platform/Plurais";
import { PontaDeLanca } from "./Components/Platform/PontaDeLanca";
import { QuadrilhaJunina } from "./Components/Platform/QuadrilhaJunina";
import { SeteFlechas } from "./Components/Platform/SeteFlechas";
import { SoulDoMonte } from "./Components/Platform/SoulDoMonte";
import { TarumaAlive } from "./Components/Platform/TarumaAlive";
import { VidasIndigenas } from "./Components/Platform/VidasIndigenas";
import { Yasua } from "./Components/Platform/Yasua";


//import api from "./Api";
//import axios from "axios";

export function App() {
  
  return (
    <Router>
      <GlobalStyle />
      <Header />
      <Routes>
        <Route path='/' element={
                <>
                  <Home />
                  <Know />
                  <Diary />
                  <Promo />
                  <Publi />
                  <Contact />
                </>} />

        <Route path='/About' element={<About />} />
        <Route path='/Platform' element={<Platform />} />
        <Route path='/Platform/Akim' element={<Akim />} />
        <Route path='/Platform/Amism' element={<Amism />} />
        <Route path='/Platform/AssocFanm' element={<AssocFanm />} />
        <Route path='/Platform/Assotram' element={<Assotram />} />
        <Route path='/Platform/CasaMatagal' element={<CasaMatagal />} />
        <Route path='/Platform/Daoca' element={<Daoca />} />
        <Route path='/Platform/EnsinoParaTodos' element={<EnsinoParaTodos />} />
        <Route path='/Platform/Escangalho' element={<Escangalho />} />
        <Route path='/Platform/EscolinhaSk8' element={<EscolinhaSk8 />} />
        <Route path='/Platform/HackerUrbano' element={<HackerUrbano />} />
        <Route path='/Platform/HipHop' element={<HipHop />} />
        <Route path='/Platform/InhaaBe' element={<InhaaBe />} />
        <Route path='/Platform/Jofo' element={<Jofo />} />
        <Route path='/Platform/Kokama' element={<Kokama />} />
        <Route path='/Platform/MaisSaudeVc' element={<MaisSaudeVc />} />
        <Route path='/Platform/MalocaSocial' element={<MalocaSocial />} />
        <Route path='/Platform/Mandala' element={<Mandala />} />
        <Route path='/Platform/Manifesta' element={<Manifesta />} />
        <Route path='/Platform/Marupiara' element={<Marupiara />} />
        <Route path='/Platform/Mura' element={<Mura />} />
        <Route path='/Platform/PedrocaArt' element={<PedrocaArt />} />
        <Route path='/Platform/Pentecostal' element={<Pentecostal />} />
        <Route path='/Platform/Plurais' element={<Plurais />} />
        <Route path='/Platform/PontaDeLanca' element={<PontaDeLanca />} />
        <Route path='/Platform/QuadrilhaJunina' element={<QuadrilhaJunina />} />
        <Route path='/Platform/SeteFlechas' element={<SeteFlechas />} />
        <Route path='/Platform/SoulDoMonte' element={<SoulDoMonte />} />
        <Route path='/Platform/TarumaAlive' element={<TarumaAlive />} />
        <Route path='/Platform/VidasIndigenas' element={<VidasIndigenas />} />
        <Route path='/Platform/Yasua' element={<Yasua />} />
        
        <Route path='/FaceB' element={<FaceB />} />
        <Route path='/Medium' element={<Medium />} />
        <Route path='/Insta' element={<Insta />} />
        <Route path='/Form' element={<Form />} />
        <Route path='/Spotify' element={<Spotify />} />
        <Route path='/YouTube' element={<YouTube />} />
      </Routes>
    </Router>
  );
}
