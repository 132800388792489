import { useEffect } from "react";

export function Insta() {
  
  useEffect(() => {
    window.location.replace('https://www.instagram.com/aihhuam/')
  }, []);
  
  return (
    <>
      <br/>
      <h1>Redirecionando...</h1>
    </>
    
  )

}