import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  width: 1356px;
  height: 1080px;
  justify-content: center;
  margin: 0;
  background-color: var(--background);
  
  
  div.body {
    border-color: 1 solid #777777;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: 540px;
    text-align: center;
  
    h1{
      align-items: center;
      text-align: center;
      align-content: center;
      margin-top: 150px;
      font-size: 45px;
      margin-right: 450px;
    }
   
    h3{
      align-items: left;
      align-content: left;
      text-align: left;
      font-size: 22px;
      margin-top: 15px;
      margin-right: 600px;
      color: var(--font-color);
    }
  } 
`;

export const Content = styled.form`
  margin-bottom: 670px;
  input {
    font-size: 20px;
    font-weight: 400;
    background: var(--font-color-sec);
    color: var(--font-color);
    border: 0 solid var(--font-color-sec);
    height: 70px;
    width: 670px;
    border-radius: 5px;
    margin: 0px;
    &::placeholder{
      padding: 8px;
      color: var(--font-color-ter);
    }
  }
  
  span {
    font-size:20px;
    color: black;
  }

  button {
    font-size: 20px;
    margin-top: 30px;
    margin-left: 20px;
    border-radius: 40px;
    height: 70px;
    width: 260px;
    background-color: var(--button);
    border: 0;
    color: var(--font-color-sec);

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const Footer = styled.div`
  
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
  height: 540px;
  background-color: var(--font-color-sec);

  div.social {
    
    img {
        margin-top: 168px;
        max-width: 55px;
        max-height: 55px;
        margin-right: 30px;
      }
  }

  h6 {
    margin-top: 30px;
    margin-bottom: 100px;
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    color: var(--font-color);
    strong {
      font-weight: 600;
    }
    
  } 
  div.letsio {
    margin-top: 20px;
    background-color: #5157cf;
    width: 100%;
    max-width: 1920px;
    height: 120px;  
      h6 {
        color: #f2f2f2;
        justify-content: center;
        align-items: center;
        text-align: center;

      }
  }
`;

