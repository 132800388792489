import styled from "styled-components";
//import bgPlataform from "../../assets/BgPlatform.png"


export const Container = styled.div`
    //background-image: url();
    object-fit: cover;
    vertical-align: bottom;
    height: 100%;
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    //max-width: 1920px;
    //min-width: 100%;
    max-height: 100%;
    width: 1366px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1{
      text-align: center;
      font-size: 45px;
      margin-top: 150px;
      color: var(--font-color);
      border: 50px;
    }
    h6 {
      margin-top: 20px;
      text-align: center;
      font-size: 20px;
      font-weight: 300;
      color: var(--font-color);
      margin-bottom: 90px;
    }
 
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: left;

`;

export const Categories = styled.div`
  font-size: 35px;
  border-radius: 5px;
  font-weight: 500;
  max-width: 1200px;
  max-height: 2500px;
  margin-left: 90px;
  margin-bottom: 30px;
  border-radius: 5px;
  color: var(--font-color);
  background-color: #ffffff;
  
  button {
    margin-left: 7px;
    font-size: 50px;
    border: #ffffff;
    background-color: #ffffff;
    align-items: center;
    margin-right: 15px;
  }

  h1{
    display: flex;
    align-items: flex-start;
    margin: 0;
  }

  div {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    
    button {
    margin-left: 130px;
    font-size: 20px;
    border-radius: 20px;
    margin-top: 30px;
    height: 150px;
    width: 524px;
    background-color: #f8f8f8;
    border: 0;
    color: var(--font-color);
    transition: filter 0.5s;

    &:hover {
      color: #ffffff;
      background-color: var(--button);
    }
  } 
  button.last {
      margin-bottom: 70px;
  } 
}


`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
