import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
//import { Link } from 'react-router-dom';
import EscangalhoLogo from '../../../assets/PLogos/EscangalhoCultural.png'

export function Escangalho() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={EscangalhoLogo} alt="logo"></img>
          <div>
                <h1>Escangalho Cultural</h1>
                <h6>Arte, Cultura</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            O projeto leva atividades de dança, artes plásticas, literatura, capoeira, música, artesanato e<br/>
            entretenimento para o público carente da zona leste da cidade e conta com forte<br/>
            atuação com os  moradores de rua e pessoas em vulnerabilidade social.<br/>
            A implementação de, PECS nos espaços para catadores é também um dos principais propósitos<br/>
            visando a sustentabilidade na região.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Leste<br/>
            Jorge Texeira
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Moradores e pessoas em situação de vulnerabilidade da zona Leste
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Jonatas Vicente
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 99368-6980<br/>
            jonavegante33@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a 
            href='https://www.instagram.com/escangalho_cultural'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
