import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
import fbImg from '../../../assets/facebookicon.png';
//import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
//import { Link } from 'react-router-dom';

export function EnsinoParaTodos() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={fbImg} alt="logo"></img>
          <div>
                <h1>Escola Ensino para Todos</h1>
                <h6>Educação</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            A Escola indígena Kokama é focada no fortalecimento da identidade e fomentação da cultura,<br/>
            preservando através de atividades e programações.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Oeste<br/>
            Monte dos Oliveiras
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Indígenas e não indígenas.
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Daniel de Oliveira Cordeiro
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 98595-7359<br/>
            comunidadeindigenaukanuan@yahoo.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
