import React from 'react'
import { Container, Content } from './styles'

import { FacebookShareButton, 
         FacebookIcon, 
         WhatsappShareButton, 
         WhatsappIcon,
         TelegramShareButton,
         TelegramIcon } from 'react-share'



function Modal({ closeModal }) {
  return(
    <>
      <Container>
        <div>
          <button onClick={() => closeModal(false)}> X </button>
        </div>
        <div>
        <h3>Compartilhar</h3>
        </div>
        <Content>
        <FacebookShareButton url="www.namaloca.com">
          <FacebookIcon size={80} round={true}/>
        </FacebookShareButton>
        <WhatsappShareButton url="www.namaloca.com">
          <WhatsappIcon size={80} round={true}/>
        </WhatsappShareButton>
        <TelegramShareButton url="www.namaloca.com">
          <TelegramIcon size={80} round={true}/>
        </TelegramShareButton>
        </Content>
      </Container>
    </>
    
  )
}
export default Modal;