import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
//import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import PentecostalLogo from '../../../assets/PLogos/JuventudePastoral.png'
//import { Link } from 'react-router-dom';

export function Pentecostal() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={PentecostalLogo} alt="logo"></img>
          <div>
                <h1>Igreja de Deus Pentecostal do Brasil</h1>
                <h6>Saúde e Bem estar, Arte, Cultura e Esporte</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            O projeto oferta cursos livres de teatro, dança contemporânea, jiu-jitsu,<br/>
            além de conteúdos espirituais e emocionais (palestras, rodas de conversa,<br/> 
            mediados por profissionais da área de psicologia e outros).
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Loteamento Águas Claras - Cidade Nova
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Público infanto juvenil e adultos com idade média de 35 anos
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Talita Medeiros
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 993180459<br/>
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
