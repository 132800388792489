import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import HackerLogo from '../../../assets/PLogos/HackerUrbano.png'
//import { Link } from 'react-router-dom';

export function HackerUrbano() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={HackerLogo} alt="logo"></img>
          <div>
                <h1>Hacker Urbano</h1>
                <h6>Arte, Cultura e Educação</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            O projeto abrange a arte em multilinguagens (lambe-lambe, grafite, pintura, fotografia e etc)<br/>
            que tem a periferia como ponto de partida de suas discussões e intervenções<br/>
            artísticas. As ações são descentralizadas e trazem visibilidade aos artistas moradores de<br/>
            regiões também periféricas valorizando experiências e vivências reais em suas comunidades.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Leste<br/>
            Zumbi dos Palmares I
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Crianças e jovens
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Geovana Balbi
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 98463-8593<br/>
            hackerurb.mao@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a 
            href='https://www.instagram.com/hackerurbano'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </a>
          <a 
            href='http://www.hackerurbanoprojeto.com'> 
          </a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
