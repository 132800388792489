import { Link } from 'react-router-dom'
import plataformaImg from '../../assets/plataforma.png'
import { C, Container, Content, S, Sc } from './styles'
import { useState } from 'react'
import Modal from '../Modal'

export function Know() {

  const [openModal, setOpenModal] = useState(false)

  return (
    <Container className='know'>
          <h1>Conheça indivíduos, coletivos, iniciativas, projetos<br/>
              e ações que transformam vidas</h1>
          <h6> Nós somos uma espécie de Tinder para que pessoas encontrarem um match com<br/>
                projetos sociais desenvolvidos dentro do estado</h6> 
            <Content>
              <img src={plataformaImg} alt='imagem'/>
            </ Content>
            <Sc>
                <S>
                  <strong>Conhece alguém que precisa<br/>conhecer a plataforma?</strong>
                  <button className="share" onClick={() => {setOpenModal(true)}}>Compartilhar</button>
                </S>
                <C>
                  <strong>Conheça a plataforma e<br/>conecte com a nossa rede</strong>
                  <Link to='/Platform'>
                    <button className="connect">Conhecer</button>
                  </Link>
                </C>
               </Sc>
        {openModal && <Modal closeModal={setOpenModal}/>}  
    </Container>
  )
}