import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
//import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import SeteFelchasLogo from '../../../assets/PLogos/AldeiaSeteFlechas.jpeg'
//import { Link } from 'react-router-dom';

export function SeteFlechas() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={SeteFelchasLogo} alt="logo"></img>
          <div>
                <h1>Aldeia Sete Flechas</h1>
                <h6>Identidade étnica, Renda e economia</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            Localizada na Zona Rural da cidade, a Aldeia abriga cerca de 60 famílias indígenas e através<br/>
            do turismo e agricultura familiar desenvolvem de maneira coletiva a qualidade de<br/>
            vida dos que ali habitam. Uma média de 60 famílias de indígenas, coordenadas pela<br/>
            Cacique Tereza, tendo um público de gênero misto e faixa etária variada, sendo todos indígenas de baixa renda.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Rural<br/>
            Km 32, AM 010
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Indígenas
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Teresa Munduruku
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 98485-7077<br/>
            mundunrukumoraes@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
