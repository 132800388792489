import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import AmismLogo from '../../../assets/PLogos/AMISM.jpg'
import { FooterPlat } from '../../FooterPlat';
//import { Link } from 'react-router-dom';

export function Amism() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={AmismLogo} alt="logo soul do monte"></img>
          <div>
                <h1>AMISM (Associação Mulheres indígenas Sateré Mawé)</h1>
                <h6>Identidade étnica, Direitos indígenas, Renda e economia</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            A Associação das Mulheres Indígenas Sateré-Mawé AMISM é uma entidade não<br/>
            governamental, sem fins lucrativos, fundada em 1996 para atender as necessidades e<br/>
            defender os direitos das mulheres indígenas da etnia, através da reivindicação de seus<br/>
            direitos e o desenvolvimento sustentável de suas comunidades. A associação foca na questão<br/>
            da mulher indígena, priorizando a proteção das terras, o bem estar social,<br/>
            cultural e econômico, e exerce papel importante na divulgação das informações gerais para as<br/>
            comunidades no que se refere às lutas indígenas.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Oeste<br/>
            Compensa
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Mulheres indígenas, com faixa etária de 20 a 60 anos, estado civil variado, baixa renda e escolaridade não completa em sua maioria.
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Samela Saté Mawé
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 98285-5077<br/>
            mulheres.amism@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a 
            href='https://www.instagram.com/amism_sateremawe'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
