import styled from "styled-components";
import bgImg from "../../assets/1bg-hd.png"


export const Container = styled.div`
 
    background-image: url(${bgImg});
    -webkit-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    width: 1356px;
    height: 974px;
    margin: 0;
    

  div.body {
    display: flex;
    flex-direction: column;
    padding-top: 330px;
    padding-left: 60px;
    
    img {
      max-width: 673px;
      max-height: 103px;
    }
    
    h1{
      font-size: 40px;
      margin-top: 50px;
      color: var(--font-color-sec);
      border: 50px;
    }

   
  } 
`;

export const Content = styled.form`
  margin-bottom: 180px;
  input {
    font-size: 20px;
    font-weight: 400;
    background: var(--backgrond);
    color: var(--font-color);
    border: 0 solid #f2f2f2;
    height: 70px;
    width: 399px;
    border-radius: 5px;
    margin: 0px;
    &::placeholder{
      padding: 8px;
      color: var(--font-color-ter);
    }
  }
  span {
    font-size:20px;
    color: var(--font-color-sec);
  }

  button {
    font-size: 20px;
    margin-left: 15px;
    margin-top: 140px;
    border-radius: 40px;
    height: 70px;
    width: 260px;
    background-color: var(--button);
    border: 0;
    color: var(--font-color-sec);

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  } 
`;


