
import promoImg from '../../assets/promo.png'
import spotfyImg from '../../assets/logoSpotfy.svg'
import youtubeImg from '../../assets/logoYoutube.svg'
import barraImg from '../../assets/barra.svg'
import { Container, Content, S, Sc } from './styles'
import { Link } from 'react-router-dom';


export function Promo() {
  return (
    <Container className='promo'>
        <div className='text'>
          <h1>#DivulgaAmazonas</h1>
          <h2> TOP ARTISTAS DO AMAZONAS QUE VOCÊ PRECISA OUVIR AGORA</h2> 
        </div>

        <div>
          <img className='bar' src={barraImg} alt='separador branco'/>
        </div>
            <Content>
              <Link to='/Spotify'><img src={promoImg} alt='imagem'/></Link>
            </ Content>
            <Sc>
                <S>
                  <Link to='/Spotify'><img className='spotfy'src={spotfyImg} alt='logo spotfy'/></Link>
                  <Link to='/Youtube'><img className='youtube' src={youtubeImg} alt='logo youtube'/></Link>
                  <br/>
                  <strong>Clique e ouça na plataforma<br/>de sua preferência</strong> 
                </S>         
            </Sc>       
    </Container>
  )
}