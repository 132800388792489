import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
import fbImg from '../../../assets/facebookicon.png';
//import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
//import { Link } from 'react-router-dom';

export function AssocFanm() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={fbImg} alt="logo"></img>
          <div>
                <h1>Associação Fanm nwa mulheres imigrantes e refugiados empoderados</h1>
                <h6>Inclusão de Refugiados</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            A associação de mulheres imigrantes negras para imigrantes e refugiados atende mulheres e<br/>
            crianças dando suporte a este público que vive em grande parte, em situação de<br/>
            vulnerabilidade na cidade de Manaus.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Norte<br/>
            Cidade Nova
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Mulheres e crianças imigrantes/refugiados
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Gloriane Aimable Antoine
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 99967-4558<br/>
            fanmnwa10hb@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>

        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
