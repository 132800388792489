import { useEffect } from "react";

export function Form() {
  
  useEffect(() => {
    window.location.replace('https://forms.gle/h3Dhu15wj6gys4Xy8')
  }, []);
  
  return (
    <>
      <br/>
      <h1>Redirecionando...</h1>
    </>
  )

}