import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
import logoSoul from '../../../assets/PLogos/SoulDoMonteLogo.png'
import fbImg from '../../../assets/facebookicon.png'
import { FooterPlat } from '../../FooterPlat';
//import { Link } from 'react-router-dom';

export function SoulDoMonte() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={logoSoul} alt="logo soul do monte"></img>
          <div>
                <h1>Soul do Monte</h1>
                <h6>Educação / Arte e Cultura</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            O coletivo desenvolve atividades, ações, eventos e inserções de moradores da região<br/>
            visando a educação, fomentação cultural e mudança num local de altos índices de violência,<br/>
            com propósito de melhorias para a região.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Norte<br/>
            Monte das Oliveiras
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Jovens e adolescentes
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
            Rojeferson Moraes
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 99188 7806<br/>
            souldomonte.projeto.social@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a href='https://www.facebook.com/coletivosouldomonte'><img className='fb'src={fbImg} alt='logo facebook'/></a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
