import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import VidasLogo from '../../../assets/PLogos/VidasIndigenasImportam.jpg'
//import { Link } from 'react-router-dom';

export function VidasIndigenas() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={VidasLogo} alt="logo"></img>
          <div>
                <h1>Vidas Indígenas Importam</h1>
                <h6>Identidade étnica, Direito Indigena, Renda e Economia</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            Projeto criado na pandemia, por indígenas e não indígenas, para<br/>
            prestar assistência básica de alimentos e itens de higiene<br/>
            para os indígenas em contexto urbano.<br/> Atualmente desenvolve<br/>
            projetos de fomento à qualidade de vida e principalmente a economia<br/>
            sustentável de comunidades indígenas.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Rural<br/>
            Tarumã
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Indígenas
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Loren Luniere
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 984011115<br/>
            vidasindigenasimportam.adm@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a 
            href='https://www.instagram.com/vidasindigenasimportam'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </a>
          <a 
            href=' https://www.facebook.com/vidasindigenasimportam'>
            <img className='insta'src={fbImg} 
              alt='logo facebook'/>
          </a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
