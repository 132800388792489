import {
  Container,
  Content,
  Categories,
} from './styles';

import { FooterPlat } from '../FooterPlat';
import { Link } from "react-router-dom";
import { useState } from 'react';

export function Platform() {
  
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)
  const [show3, setShow3] = useState(false)
  const [show4, setShow4] = useState(false)
  const [show5, setShow5] = useState(false)
  const [show6, setShow6] = useState(false)
  const [show7, setShow7] = useState(false)
  const [show8, setShow8] = useState(false)
  const [show9, setShow9] = useState(false)
  const [show10, setShow10] = useState(false)
  
  const toggle = () => {
    setShow(!show)
  }
  const toggle2 = () => {
    setShow2(!show2)
  }
  const toggle3 = () => {
    setShow3(!show3)
  }
  const toggle4 = () => {
    setShow4(!show4)
  }
  const toggle5 = () => {
    setShow5(!show5)
  }
  const toggle6 = () => {
    setShow6(!show6)
  }
  const toggle7 = () => {
    setShow7(!show7)
  }
  const toggle8 = () => {
    setShow8(!show8)
  }
  const toggle9 = () => {
    setShow9(!show9)
  }
  const toggle10 = () => {
    setShow10(!show10)
  }
  return (
    <>
      <Container>
        <h1>Bem vindx à plataforma Namaloca</h1>
        <h6>Queremos conectar você a projetos sociais desenvolvidos do Amazonas. Fique à vontade<br />
          para pesquisar, fazer contato e, principalmente, engajar!</h6>
        <Content>
          <Categories className='Art'>
            <h1>
              <button onClick={toggle}>{show ? '-' : '+' }</button>Arte e cultura
            </h1>
            <div>
              {
                show?
                <>
                  <Link to='/Platform/Escangalho'>
                    <button type='submit'>Escangalho Cultural</button>
                  </Link>
                  <Link to='/Platform/SoulDoMonte'>
                    <button type='submit'>Soul do Monte</button>
                  </Link>
                  <Link to='/Platform/Daoca'>
                    <button type='submit'>Daoca Sessions</button>
                  </Link>
                  <Link to='/Platform/HipHop'>
                    <button type='submit'>Hip Hop Abraça</button>
                  </Link>
                  <Link to='/Platform/HackerUrbano'>
                    <button type='submit'>Hacker Urbano</button>
                  </Link>
                  <Link to='/Platform/Mandala'>
                    <button type='submit'>Coletivo Mandala</button>
                  </Link>
                  <Link to='/Platform/QuadrilhaJunina'>
                    <button className='last' type='submit'>Dança Folclórica Quadrilha Baru Junina</button>
                  </Link>
                </>:null
              }
              </div>
          </Categories>
          <Categories className='Ed'>
            <h1>
              <button onClick={toggle2}>{show2 ? '-' : '+' }</button>Educação
            </h1>
            <div>
              {
                show2?
                <>
                  <Link to='/Platform/SoulDoMonte'>
                    <button type='submit'>Soul do Monte</button>
                  </Link>
                  <Link to='/Platform/EscolinhaSk8'>
                    <button type='submit'>Escolinha de skate<br/>Ajudar para Transformar</button>
                  </Link>
                  <Link to='/Platform/Jofo'>
                    <button type='submit'>Casa de conhecimento Murui Bue<br/>Witoto</button>
                  </Link>
                  <Link to='/Platform/Akim'>
                    <button type='submit'>Associação dos índios Kokama<br/>residentes no município de Manaus<br/>(AKIM)</button>
                  </Link>
                  <Link to='/Platform/EnsinoParaTodos'>
                    <button className='last' type='submit'>Escola Ensino para todos</button>
                  </Link>
                </>:null
              }
            </div>
          </Categories>
          <Categories className='Sport'>
            <h1>
              <button onClick={toggle3}>{show3 ? '-' : '+' }</button>Esporte
            </h1>
            <div>
              {
                show3?
                <>
                  <Link to='/Platform/Marupiara'>
                    <button type='submit'>Projeto Marupiara de Artes Marciais</button>
                  </Link>
                  <Link to='/Platform/soul'>
                    <button type='submit'>Escolinha de skate<br/>Ajudar para Transformar</button>
                  </Link>
                  <Link to='/Platform/Pentecostal'>
                    <button className='last' type='submit'>Igreja de Deus Pentecostal do Brasil</button>
                  </Link>
                </>:null
              }
              </div>
          </Categories>
          <Categories className='Inclusion'>
          <h1>
              <button onClick={toggle4}>{show4 ? '-' : '+' }</button>Inclusão de refugiados
            </h1>
            <div>
              {
                show4?
                <>
                  <Link to='/Platform/Manifesta'>
                    <button type='submit'>Associação Manifesta LGBT+</button>
                  </Link>
                  <Link to='/Platform/AssocFanm'>
                    <button className='last' type='submit'>Associação Fanm nwa mulheres<br/>imigrantes e refugiados impoderados</button>
                  </Link>
                </>:null
              }
              </div>
          </Categories>
          <Categories className='Enviroment'>
            <h1>
              <button onClick={toggle5}>{show5 ? '-' : '+' }</button>Meio ambiente
            </h1>
            <div>
              {
                show5?
                <>
                  <Link to='/Platform/TarumaAlive'>
                    <button className='last' type='submit'>Taruma Alive</button>
                  </Link>
                </>:null
              }
              </div>
          </Categories>
          <Categories className='Economy'>
            <h1>
              <button onClick={toggle6}>{show6 ? '-' : '+' }</button>Renda e economia
            </h1>
            <div>
              {
                show6?
                <>
                  <Link to='/Platform/PedrocaArt'>
                    <button type='submit'>Pedroca Artesanatos</button>
                  </Link>
                  <Link to='/Platform/Amism'>
                    <button type='submit'>AMISM <br/> (Associação Mulheres indígenas Sateré Mawé)</button>
                  </Link>
                  <Link to='/Platform/Kokama'>
                    <button type='submit'>Associação Indígena de artesanato<br/>Kokama Monte Horebe</button>
                  </Link>
                  <Link to='/Platform/SeteFlechas'>
                    <button type='submit'>Aldeia Sete Flechas</button>
                  </Link>
                  <Link to='/Platform/Mura'>
                    <button type='submit'>Associação Raimundo Mura</button>
                  </Link>
                  <Link to='/Platform/VidasIndigenas'>
                    <button type='submit'>Vidas Indígenas Importam</button>
                  </Link>
                  <Link to='/Platform/Yasua'>
                    <button type='submit'>Yasuã iapuraki iané kuximasá</button>
                  </Link>
                  <Link to='/Platform/InhaaBe'>
                    <button type='submit'>Aldeia indígena Inhãa-bé</button>
                  </Link>
                  <Link to='/Platform/PontaDeLanca'>
                    <button className='last' type='submit'>Coletivo Ponta de Lança</button>
                  </Link>
                </>:null
              }
              </div>
          </Categories>
          <Categories className='Helth'>
            <h1>
              <button onClick={toggle7}>{show7 ? '-' : '+' }</button>Saúde e bem estar
            </h1>
            <div>
              {
                show7?
                <>
                  <Link to='/Platform/MaisSaudeVc'>
                    <button type='submit'>Mais Saúde Para Você</button>
                  </Link>
                  <Link to='/Platform/Pentecostal'>
                    <button type='submit'>Igreja de Deus Pentecostal do Brasil</button>
                  </Link>
                  <Link to='/Platform/Manifesta'>
                    <button className='last' type='submit'>Associação Manifesta LGBT+</button>
                  </Link>
                </>:null
              }
              </div>
          </Categories>
          <Categories className='Visibility'>
            <h1>
              <button onClick={toggle8}>{show8 ? '-' : '+' }</button>Visibilidade indígena
            </h1>
            <div>
              {
                show8?
                <>
                  <Link to='/Platform/Marupiara'>
                    <button type='submit'>Projeto Marupiara de Artes Marciais</button>
                  </Link>
                  <Link to='/Platform/Amism'>
                    <button type='submit'>AMISM <br/> (Associação Mulheres indígenas Sateré Mawé)</button>
                  </Link>
                  <Link to='/Platform/Jofo'>
                    <button type='submit'>Casa de conhecimento Murui Bue<br/>Witoto</button>
                  </Link>
                  <Link to='/Platform/Kokama'>
                    <button type='submit'>Associação Indígena de artesanato<br/>Kokama Monte Horebe</button>
                  </Link>
                  <Link to='/Platform/SeteFlechas'>
                    <button type='submit'>Aldeia Sete Flechas</button>
                  </Link>
                  <Link to='/Platform/Mura'>
                    <button type='submit'>Associação Raimundo Mura</button>
                  </Link>
                  <Link to='/Platform/Akim'>
                    <button type='submit'>Associação dos índios Kokama<br/>residentes no município de Manaus<br/>(AKIM)</button>
                  </Link>
                  <Link to='/Platform/VidasIndigenas'>
                    <button type='submit'>Vidas Indígenas Importam</button>
                  </Link>
                  <Link to='/Platform/Yasua'>
                    <button type='submit'>Yasuã iapuraki iané kuximasá</button>
                  </Link>
                  <Link to='/Platform/InhaaBe'>
                    <button type='submit'>Aldeia indígena Inhãa-bé</button>
                  </Link>
                  <Link to='/Platform/EnsinoParaTodos'>
                    <button type='submit'>Escola Ensino para todos</button>
                  </Link>
                  <Link to='/Platform/MalocaSocial'>
                    <button type='submit'>Maloca Social IAPOAM</button>
                  </Link>
                  <Link to='/Platform/Plurais'>
                    <button className='last' type='submit'>Plurais</button>
                  </Link>
                </>:null
              }
              </div>
          </Categories>
          <Categories className='Lgbq'>
            <h1>
              <button onClick={toggle9}>{show9 ? '-' : '+' }</button>Visibilidade lgbtqia+
            </h1>
            <div>
              {
                show9?
                <>
                  <Link to='/Platform/Manifesta'>
                    <button type='submit'>Associação Manifesta LGBT+</button>
                  </Link>
                  <Link to='/Platform/Assotram'>
                    <button type='submit'>ASSOTRAM<br/>Associação de travestis, transexuais e trangeneros do Amazonas.</button>
                  </Link>
                  <Link to='/Platform/Plurais'>
                    <button type='submit'>Plurais</button>
                  </Link>
                  <Link to='/Platform/soul'>
                    <button type='submit'>Associação dos índios Kokama<br/>residentes no município de Manaus<br/>(AKIM)</button>
                  </Link>
                  <Link to='/Platform/soul'>
                    <button type='submit'>Escola Ensino para todos</button>
                  </Link>
                  <Link to='/Platform/CasaMatagal'>
                    <button className='last' type='submit'>Casa Matagal ballroom</button>
                  </Link>
                </>:null
              }
              </div>
          </Categories>
          <Categories className='Race'>
            <h1>
              <button onClick={toggle10}>{show10 ? '-' : '+' }</button>Visibilidade negra
            </h1>
            <div>
              {
                show10?
                <>
                  <Link to='/Platform/CasaMatagal'>
                    <button type='submit'>Casa Matagal ballroom</button>
                  </Link>
                  <Link to='/Platform/PontaDeLanca'>
                    <button className='last' type='submit'>Coletivo Ponta de Lança</button>
                  </Link>
                </>:null
              }
              </div>
          </Categories>
        </ Content>
      </Container>
      <FooterPlat />
    </>
  )
}
