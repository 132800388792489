import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
import fbImg from '../../../assets/facebookicon.png';
//import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
//import { Link } from 'react-router-dom';

export function Yasua() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={fbImg} alt="logo"></img>
          <div>
                <h1>Yasuã iapuraki iané kuximasá</h1>
                <h6>Identidade étnica, Direito indígena, Renda e economia</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            O projeto visa o fortalecimento da identidade indigena com produção de artesanatos, além de<br/>
            defesa e incentivo da prática da língua materna como forma de manter as raízes em áreas urbanas.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Rural<br/>
            Parque das Tribos
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Adolescentes e mulheres
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Ismael Munduruku
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 99167-4140<br/>
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
