import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
//import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import LogoInhaabe from '../../../assets/PLogos/AldeiaInhaabe.jpeg'
//import { Link } from 'react-router-dom';

export function InhaaBe() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={LogoInhaabe} alt="logo"></img>
          <div>
                <h1>Aldeia indígena Inhãa-bé</h1>
                <h6>Identidade étnica, Direito indígena, Renda e economia</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            O projeto trabalha com produção de artesanatos, roupas e turismo,<br/>
            além de oferecer educação e fomento da cultura indígena e trabalharmos<br/>
            nosso grupo musical Kui'a. O objetivo  é fortalecer a identidade dos moradores da comunidade.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Rural<br/>
            Tarumã
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Faixa etária de 5 à 50 anos de todos os gêneros e classes sociais e de todos os níveis de escolaridade.
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Yrá tikuna Regiane Cruz
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 98460-2913<br/>
            regianetikuna@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>

        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
