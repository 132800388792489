import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import JiquitaiaLogo from '../../../assets/PLogos/JiquitaiaEscolinhadeSkate.png'
//import { Link } from 'react-router-dom';

export function EscolinhaSk8() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={JiquitaiaLogo} alt="logo"></img>
          <div>
                <h1>Escolinha de Skate -  Ajudar para Transformar</h1>
                <h6>Educação e Esporte</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            O projeto é  uma iniciativa que oferece escolinha de skate, oficinas de inglês e fotografia para,<br/>
            um público infanto juvenil que estuda em escolas públicas. Nosso objetivo é formar<br/>
            cidadãos com qualidade de ensino e recursos profissionais e recreativos.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Leste<br/>
            Coroado 2
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Crianças de 5 a 16 anos
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Francileno Taveira
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 98236-6444<br/>
            institutojiquitaia@hotmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a 
            href='https://www.instagram.com/instituto_jiquitaia'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
