import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 1080px;
    width: 1356px;
    display: flex;
    justify-content: space-around;
    align-items: center;
        
 
`;
export const Content = styled.div`
  img {
  width: 674px;
  height: 1080px;
  }
`;

export const Sc = styled.div`
  margin-top: 100px;
  background-color: var(--font-color-sec);
  width: 690px;
  height: 685px;
  border-radius: 5px;
  border: 0 solid #f2f2f2;
  //margin-left: 120px;
  //margin-right: 100px;

  h1{
      text-align: center;
      justify-content: center;
      font-size: 45px;
      padding-top: 120px;
      color: var(--font-color);
      border: 50px;
    }
    h6 {
      margin-top: 20px;
      justify-content: center;
      text-align: center;
      font-size: 20px;
      font-weight: 300;
      color: var(--font-color);
    }
`;


export const C = styled.div`
text-align: center;
margin-top: 70px;
display: flex;
justify-content: center;
align-items: center;

  button.test {
    //margin-left: 70px;
    margin-top: 20px;
    font-size: 20px;
    border-radius: 40px;
    height: 70px;
    width: 260px;
    background-color: var(--button);
    border: 0;
    color: var(--font-color-sec);
    align-items: center;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }  
`; 



   

