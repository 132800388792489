import { Link } from 'react-router-dom'
import { Container, Footer, FooterDiv, Text } from './styles'


export function FooterPlat() {
  return (
    <Container>
        <Footer>
          <FooterDiv>
            <Text>
            Quer se cadastrar na plataforma?<br/>
            É só preencher o formulário
            </Text>
            <Link to='/Form' target="_blank" rel="noreferrer">
              <button type="submit">Cadastrar</button>
            </Link>
          </FooterDiv>
        </Footer>  
    </Container>
  )
}