import { useEffect } from "react";

export function FaceB() {
  
  useEffect(() => {
    window.location.replace('https://www.facebook.com/aihhuam/')
  }, []);
  
  return (
    <>
      <br/>
      <h1>Redirecionando...</h1>
    </>
  )

}