import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import AssotramLogo from '../../../assets/PLogos/ASSOTRAM.jpeg'
//import { Link } from 'react-router-dom';

export function Assotram() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={AssotramLogo} alt="logo"></img>
          <div>
                <h1>ASSOTRAM - Associação de travestis, transexuais e trangeneros do Amazonas</h1>
                <h6>Visibilidade lgbtqia+</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            A ASSOTRAM foi fundada em 2017 com o objetivo de lutar por direitos da população LGBT+,<br/>
            principalmente de travestis e transexuais. A associação é sem fins lucrativos e<br/>
            de natureza não governamental, sem fins políticos partidários, luta por políticas<br/>
            públicas, igualdade de direitos e a visibilidade desses grupos. A associação promove<br/>
            workshops, presta assistência social e também jurídica, como é o caso do projeto<br/>
            "advoca-se", que retificou mais de 35 registros de nascimento de travestis, mulheres e homens trans.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus e alguns municípios do AM<br/>
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Travestis e transexuais, com faixa etária de 16 a 35 anos, baixa renda e muitas com ensino médio incompleto. 
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Joyce Alves
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 99461-0569<br/>
            assotram@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a 
            href='https://www.instagram.com/assotram'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
