import { useEffect } from "react";

export function YouTube() {
  
  useEffect(() => {
    window.location.replace('https://www.youtube.com/channel/UCnRV2EfyRRaunAgVhkLDCDw')
  }, []);
  
  return (
    <>
      <br/>
      <h1>Redirecionando...</h1>
    </>
  )

}