import { Container, Content, Footer } from './styles'
import { Link } from 'react-router-dom';
import logoMedium from '../../assets/mediumlogoicon.png'
import logoFb from '../../assets/facebookicon.png'
import logoInsta from '../../assets/instagramicon.png'
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = Yup.object({
  email: Yup.string().email("Formato de e-mail inválido.")
}).required();


export function Contact() {

  const { register, handleSubmit, formState:{errors}} = useForm({
    resolver: yupResolver(schema)
  });
  
  function onSubmit(email:any) {
    console.log(email);
  }

  return (
    <Container className='contact'>
        <div className='body'>
          <h1>Conecte e engaje com a gente</h1>
          <h3>A gente não vai te encher de spam, relaxa!</h3>
            <Content onSubmit={handleSubmit(onSubmit)}>
              <input type="text"
              {...register("email", {required: true})}
              placeholder='Seu melhor e-mail'
              />
              <button className="button" type="submit">Conectar para engajar</button>
              <br/>
              <span>{errors.email?.message}</span>
            </ Content>
        </div> 
            <Footer>
              <div className='social'>
              <Link to='/Medium' target="_blank" rel="noreferrer">
                <img src={logoMedium} alt='Medium'/></Link>
              <Link to='/FaceB' target="_blank" rel="noreferrer">
                <img src={logoFb} alt='Facebook'/></Link>
              <Link to='/Insta' target="_blank" rel="noreferrer">
                <img src={logoInsta} alt='instagram'/></Link>
              </div>
              <h6>Nós amamos receber e-mail e respondemos TODOS. Então, se precisar falar<br/> 
                  com a gente é só dar um alô pelo <strong>contato@namaloca.com</strong>
              </h6>
              <div className='letsio'>
                <h6>Este site foi desenvolvido por <strong>letsio</strong></h6>
              </div>
            </Footer>
          
    </Container>
  )
}