import { useEffect } from "react";

export function Medium() {
  
  useEffect(() => {
    window.location.replace('https://namaloca.medium.com/')
  }, []);
  
  return (
    <>
      <br/>
      <h1>Redirecionando...</h1>
    </>
    
  )

}