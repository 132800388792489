import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
//import { Link } from 'react-router-dom';

export function Manifesta() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={fbImg} alt="logo"></img>
          <div>
                <h1>Associação Manifesta LGBT+</h1>
                <h6>Visibilidade lgbtqia+</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            É a única casa da região norte que acolhe LGBTQA+ em estado de vulnerabilidade e violência<br/>
            sexual, com o projeto Casa Miga que nasceu para garantir o acolhimento de<br/>
            brasileiros e refugiados expulsos de casa e/ou em situação de vulnerabilidade social,<br/>
            devido a sua orientação sexual e/ou identidade de gênero. Na estadia, a Casa<br/>
            busca garantir o apoio biopsicossocial, com ênfase na saúde e educação com<br/>
            olhar na formação profissional para possibilitar a inserção dessas pessoas no mercado de trabalho.<br/>
            Já passaram pela casa mais de 100 LGBTs, entre brasileiros, cubanos e<br/>
            venezuelanos, considerando o tempo de estadia de até 90 dias para cada acolhido. 
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Centro<br/>
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Atendem os grupos LGBT+ de brasileiros, refugiados e imigrantes, sendo a maioria com ensino fundamental incompleto, com idade que varia de 18 à 47 anos.
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Karen Aline
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 99298-7970<br/>
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a 
            href='https://www.instagram.com/casamigalgbt'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
