import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  width: 1356px;
  height: 1080px;
  justify-content: center;
  margin: 0;
  background-color: var(--background);
  
  div.body {
    border-color: 1 solid #777777;
    display: flex;
    align-items: left;
    margin-left: 90px;
    flex-direction: column;
  
    h1{
      align-items: left;
      text-align: left;
      align-content: left;
      margin-top: 100px;
      font-size: 45px;
      font-weight: 700;
      margin-right: 450px;
    }
   
    h3{
      align-items: left;
      align-content: left;
      text-align: left;
      font-size: 35px;
      font-weight: 300;
      margin-top: 15px;
      color: var(--font-color);
    }
    h3.team {
        align-items: left;
        align-content: left;
        text-align: left;
        font-size: 35px;
        font-weight: 300;
        margin-top: 15px;
        color: var(--font-color);
        margin-bottom: 90px;
      }
  } 
`;



