import logoAihh from '../../assets/LOGOAIHHUAM.png'
import { Container, Content, Li } from './styles'
import { useState } from 'react'
import { Link as LinkScroll } from 'react-scroll'

export const Header = () => {
  const [click, setClick] = useState(false);
  
  const handleClick = () => setClick(!click);

  return (
    <Container>
      <Content className='nav-content'>
      <ul onClick={handleClick} className='nav-itens'>
        <LinkScroll 
          activeClass='active'
          to='home'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <Li to=''>Home</Li>
        </LinkScroll>

        <Li to='About'>Sobre</Li>
        <Li to='Platform'>Plataforma</Li>

        <LinkScroll 
          activeClass='active'
          to='diary'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
        </LinkScroll>
        <LinkScroll 
          activeClass='active'
          to='fest'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
        </LinkScroll>
        <LinkScroll 
          activeClass='active'
          to='contact'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          <Li to=''>Contato</Li>
        </LinkScroll>
      </ul>
        <img src={logoAihh} alt="Logo parceiros i c s"/>
      </Content>
    </Container>
 
  )
}

export default Header;