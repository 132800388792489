import { FooterPlat } from '../FooterPlat'
import { Container } from './styles'


export function About() {
  return (
    <Container>
        <div className='body'>
          <h1>Sobre nós</h1>
          <h3>Namaloca é um canal de criação e conexão de valores sociais e culturais para uma<br/>
          agenda de desenvolvimento social e sustentável do Amazonas. Buscamos o fomento da
          cidadania cultural amazônida para o respeito e valorização de sua diversidade étnica e
          regional, o pertencimento territorial cidade-floresta, e o acesso a informações<br/>
          qualitativas como fundamento de uma consciência política desenvolvedora.
          </h3>
          <h1>Equipe</h1>
          <h3 className='team'>
              Marcia Novo - articuladora<br/>
              Jander Manauara - articulador<br/>
              Giuliana Fletcher - assessora de comunicação<br/>
              Mel Angeoles - assessora administrativa<br/>
              Manoel Frank - gestor contábil-financeiro<br/>
              Thaianty dos Santos - gerente de projeto<br/>
              Paula Gabriel - conselheira<br/>
          </h3>
        </div>
      <FooterPlat/>          
    </Container>
   
  )
}