import agendaImg from '../../assets/bg2-hd.png'
import { Link } from 'react-router-dom'
import { C, Container, Content, Sc } from './styles'


export function Diary() {
  return (
    <Container className='diary'>
      <div>
       <Sc>
          <h1>Participe da Agenda <br/>do futuro do Amazonas
          </h1>
          <h6> Saúde, educação, saneamento básico, transporte público,<br/>
             empregabilidade, cultura, turismo, meio-ambiente...<br/>
             Toda as pautas contam! Precisamos fazer hoje o que vai ser<br/> 
             determinante para o nosso futuro como cidadãos, como cidade e <br/>
             como floresta. Ajude-nos a construir essa agenda e<br/>
             lutar pelo nosso futuro!
          </h6>
            <C>
            <Link to='/Form' target="_blank" rel="noreferrer">
              <button className="test">Participar agora</button>
            </Link>
            </C>
       </Sc>
      </div>
            <Content>
              <img src={agendaImg} alt='imagem'/>
            </ Content> 
    </Container>
  )
}