import styled from "styled-components";
import festImg from "../../assets/festival.png"


export const Container = styled.div`
 
  background-image: url(${festImg});
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  width: 100%;
  width: 1356px;
  height: 1080px;
  margin: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;


  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1{
      margin-left: 433px;
      background-color: var(--font-color-sec);
      max-width: 1055px;
      max-height: 90px;
      font-size: 70px;
      font-weight: bold;
      margin-top: 439px;
      align-items: center;
      text-align: center;
      color: var(---font-color);
      
    }
    h2{
      margin-left: 490px;
      background-color: var(--font-color-sec);
      max-width: 930px;
      max-height: 81px;
      font-size: 50px;
      margin-top: 8px;
      align-items: center;
      text-align: center;
      align-content: center;
      color: var(--font-color);
      
    }

    strong {
      margin-left: 1005px;
      background-color: var(--font-color-sec);
      max-width: 416px;
      max-height: 81px;
      font-size: 45px;
      margin-top: 8px;
      align-items: center;
      text-align: center;
      align-content: center;
      color: var(--font-color);
    
  }
} 
`;

export const Content = styled.form`


button {
    font-size: 20px;
    margin-left: 700px;
    margin-top: 60px;
    border-radius: 40px;
    height: 70px;
    width: 260px;
    background-color: var(--button);
    border: 0;
    color: var(--font-color-sec);

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  } 
`;


