import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import { Link } from 'react-router-dom';

export function MalocaSocial() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={fbImg} alt="logo"></img>
          <div>
                <h1>Maloca Social IAPOAM</h1>
                <h6>Identidade étnica, Direito indígena, Renda e economia</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            O Projeto Maloca Social realiza ações de resgate intercultural com objetivo de lutar<br/>
            pelo Direito e proteção dos Povos Indígenas comunidade Betel Aldeia Uka Anama.<br/>
            O foco é a inclusão do público-alvo que vem através do serviço social e psicológico.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Leste<br/>
            Parque das Garças
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Pessoas em situação de vulnerabilidade e pacientes de doenças crônicas
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Silvia Brito
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 99508-8568<br/>
            silviafbsantiago24@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <Link 
            to='https://www.instagram.com/maisssaude9'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </Link>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
