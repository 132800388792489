import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    width: 1356px;
    height: 1080px;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
      text-align: center;
      font-size: 45px;
      margin-top: 150px;
      color: var(--font-color);
      border: 50px;
    }
    h6 {
      margin-top: 20px;
      text-align: center;
      font-size: 20px;
      font-weight: 300;
      color: var(--font-color);

    }
 
`;
export const Content = styled.div`
  img {
  width: 100%;
  max-width: 1640px;
  max-height: 359px;
  }
`;

export const Sc = styled.div`
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-top: -100px;
  margin-bottom: 60px;
  border-radius: 5px;
  background-color: var(--font-color-sec);
  width: 100%;
  max-width: 840px;
  max-height: 270px;
`;

export const C = styled.div`
align-items: center;
text-align: center;
margin-top: 55px;
margin-bottom: 55px;
strong {
    text-align: center;
    font-size: 18px;
  }

  button.connect {
    font-size: 20px;
    margin-top: 30px;
    border-radius: 40px;
    height: 70px;
    width: 260px;
    background-color: var(--button);
    border: 0;
    color: var(--font-color-sec);
    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }  
`; 

export const S = styled.div`
align-items: center;
text-align: center;
margin-top: 55px;
margin-right: 20px;
margin-left: 20px;
margin-bottom: 55px;
strong {
    justify-content: center;
    text-align: center;
    font-size: 18px;
  }
button.share {
    font-size: 20px;
    margin-top: 30px;
    border-radius: 40px;
    height: 70px;
    width: 260px;
    background-color: var(--font-color-sec);
    border: 2px solid #5157cf;
    color: var(--button);

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;


   

