import { Container, Content } from './styles'

export function Publi() {
  return (
    <Container className='fest'>
        <div>
          <h1>Festival Namaloca</h1>
          <h2>A agenda do futuro do Amazonas</h2>
          <strong>28 de agosto</strong>
            <Content>
            </ Content>
        </div>  
    </Container>
  )
}