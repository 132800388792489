import { useEffect } from "react";

export function Spotify() {
  
  useEffect(() => {
    window.location.replace('https://open.spotify.com/user/316pbnt62e24yyxhryzg2mrul6fi?si=41727f97151145e8')
  }, []);
  
  return (
    <>
      <br/>
      <h1>Redirecionando...</h1>
    </>
  )

}