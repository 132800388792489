import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
import fbImg from '../../../assets/facebookicon.png';
//import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
//import { Link } from 'react-router-dom';

export function HipHop() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={fbImg} alt="logo"></img>
          <div>
                <h1>Hip Hop Abraça</h1>
                <h6>Arte, Cultura e Esporte</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            Através da cultura Hip Hop o projeto desenvolve oficinas artísticas,<br/>
            ações sociais, sorteios e atividades inclusivas para a comunidade.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Manaus - AM<br/>
            Zona Sul<br/>
            São Francisco
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Todos os públicos
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Rafaela Neves dos Santos
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 98832-9963<br/>
            rn636615@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
        
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
