import { 
  Container, 
  Content, 
  Title,
  Description,
  Area,
  Public,
  Rep,
  ContactPage,
  Social
} from './styles';
//import fbImg from '../../../assets/facebookicon.png';
import instaImg from '../../../assets/instagramicon.png';
import { FooterPlat } from '../../FooterPlat';
import PluraisLogo from '../../../assets/PLogos/Plurais.jpeg'
//import { Link } from 'react-router-dom';

export function Plurais() {
  return (
    <>
    <Container>
      <Content>
        <Title>
          <img src={PluraisLogo} alt="logo"></img>
          <div>
                <h1>PLURAIS (Coletivo)</h1>
                <h6>Visibilidade lgbtqia+</h6>
          </div>
        </Title>
        <Description>
          <strong>Breve descrição</strong>
          <h6>
            <br/>
            O Plurais é um coletivo criado há aproximadamente 2 anos que através da produção de<br/>
            conteúdos audiovisuais e consultorias, trabalha a política da diversidade atuando em <br/>
            questões de raça, gêneros, sexualidade e pessoas com deficiência. Atua muito no meio digital.
          </h6>
        </Description>
        <Area>
          <strong>Area de atuação</strong>
          <h6>
          <br/>
            Mídias digitais (online)<br/>
          </h6>
        </Area>
        <Public>
          <strong>Público atendido</strong>
          <h6>
            <br/>Público geral
          </h6>
        </Public>
        <Rep>
          <strong>Representante</strong>
          <h6>
          <br/>
          Roger
          </h6>
        </Rep>
        <ContactPage>
          <strong>Contato</strong>
          <h6>
          <br/>
            +55 92 98405-7923<br/>
            plurais.org@gmail.com
          </h6>
        </ContactPage>
        <Social>
          <strong>Redes Sociais</strong>
          <a 
            href='https://www.instagram.com/pluraisdiversidade'>
            <img className='insta'src={instaImg} 
              alt='logo instagram'/>
          </a>
        </Social>
      </Content>
    </Container>
    <FooterPlat/>
  </>
  )
}
